@use "sass:color";
// Color system

$white: #fff !default;
$grey: #808080 !default;
$black: #000 !default;

// scss-docs-start mocingbird-colors
$primary: #485cc7 !default;
$secondary: #40c1ac !default;
$accent: #6399ae !default;
$success: #29904d !default;
$info: #007bff !default;
$warning: #ffc107 !default;
$danger: #e73d44 !default;
$light: #f8f9fa !default;
$dark: #292929 !default;
$blue:  #485cc7;
$slate-grey: #6F749B;
$dark-blue: #2A3984;
// scss-docs-end mocingbird-colors

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "accent": $accent,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// primary-color varients
$primary-100: color.adjust($primary, $lightness: 80%) !default;
$primary-200: color.adjust($primary, $lightness: 60%) !default;
$primary-300: color.adjust($primary, $lightness: 40%) !default;
$primary-400: color.adjust($primary, $lightness: 20%) !default;
$primary-500: $primary !default;
$primary-600: color.adjust($primary, $lightness: -20%) !default;
$primary-700: color.adjust($primary, $lightness: -40%) !default;
$primary-800: color.adjust($primary, $lightness: -60%) !default;
$primary-900: color.adjust($primary, $lightness: -80%) !default;

// secondary-color varients
$secondary-100:  color.adjust($secondary, $lightness: 80%) !default;
$secondary-200:  color.adjust($secondary, $lightness: 60%) !default;
$secondary-300:  color.adjust($secondary, $lightness: 40%) !default;
$secondary-400:  color.adjust($secondary, $lightness: 20%) !default;
$secondary-500: $secondary !default;
$secondary-600:  color.adjust($secondary, $lightness: -20%) !default;
$secondary-700:  color.adjust($secondary, $lightness: -40%) !default;
$secondary-800:  color.adjust($secondary, $lightness: -60%) !default;
$secondary-900:  color.adjust($secondary, $lightness: -80%) !default;

// accent-color varients
$accent-100:  color.adjust($accent, $lightness: 80%) !default;
$accent-200:  color.adjust($accent, $lightness: 60%) !default;
$accent-300:  color.adjust($accent, $lightness: 40%) !default;
$accent-400:  color.adjust($accent, $lightness: 20%) !default;
$accent-500: $accent !default;
$accent-600: color.adjust($accent, $lightness: -20%) !default;
$accent-700: color.adjust($accent, $lightness: -40%) !default;
$accent-800: color.adjust($accent, $lightness: -60%) !default;
$accent-900: color.adjust($accent, $lightness: -80%) !default;

// success-color varients
$success-100:  color.adjust($success, $lightness: 80%) !default;
$success-200:  color.adjust($success, $lightness: 60%) !default;
$success-300:  color.adjust($success, $lightness: 40%) !default;
$success-400:  color.adjust($success, $lightness: 20%) !default;
$success-500: $success !default;
$success-600:  color.adjust($success, $lightness: -20%) !default;
$success-700:  color.adjust($success, $lightness: -40%) !default;
$success-800:  color.adjust($success, $lightness: -60%) !default;
$success-900:  color.adjust($success, $lightness: -80%) !default;

// info-color varients
$info-100:  color.adjust($info, $lightness: 80%) !default;
$info-200:  color.adjust($info, $lightness: 60%) !default;
$info-300:  color.adjust($info, $lightness: 40%) !default;
$info-400:  color.adjust($info, $lightness: 20%) !default;
$info-500: $info !default;
$info-600:  color.adjust($info, $lightness: -20%) !default;
$info-700:  color.adjust($info, $lightness: -40%) !default;
$info-800:  color.adjust($info, $lightness: -60%) !default;
$info-900:  color.adjust($info, $lightness: -80%) !default;

// warning-color varients
$warning-100:  color.adjust($warning, $lightness: 80%) !default;
$warning-200:  color.adjust($warning, $lightness: 60%) !default;
$warning-300:  color.adjust($warning, $lightness: 40%) !default;
$warning-400:  color.adjust($warning, $lightness: 20%) !default;
$warning-500: $warning !default;
$warning-600:  color.adjust($warning, $lightness: -20%) !default;
$warning-700:  color.adjust($warning, $lightness: -40%) !default;
$warning-800:  color.adjust($warning, $lightness: -60%) !default;
$warning-900:  color.adjust($warning, $lightness: -80%) !default;

// danger-color varients
$danger-100:  color.adjust($danger, $lightness: 80%) !default;
$danger-200:  color.adjust($danger, $lightness: 60%) !default;
$danger-300:  color.adjust($danger, $lightness: 40%) !default;
$danger-400:  color.adjust($danger, $lightness: 20%) !default;
$danger-500: $danger !default;
$danger-600:  color.adjust($danger, $lightness: -20%) !default;
$danger-700:  color.adjust($danger, $lightness: -40%) !default;
$danger-800:  color.adjust($danger, $lightness: -60%) !default;
$danger-900:  color.adjust($danger, $lightness: -80%) !default;

// light-color varients
$light-100:  color.adjust($light, $lightness: 80%) !default;
$light-200:  color.adjust($light, $lightness: 60%) !default;
$light-300:  color.adjust($light, $lightness: 40%) !default;
$light-400:  color.adjust($light, $lightness: 20%) !default;
$light-500: $light !default;
$light-600:  color.adjust($light, $lightness: -20%) !default;
$light-700:  color.adjust($light, $lightness: -40%) !default;
$light-800:  color.adjust($light, $lightness: -60%) !default;
$light-900:  color.adjust($light, $lightness: -80%) !default;

// dark-color varients
$dark-100:  color.adjust($dark, $lightness: 80%) !default;
$dark-200:  color.adjust($dark, $lightness: 60%) !default;
$dark-300:  color.adjust($dark, $lightness: 40%) !default;
$dark-400:  color.adjust($dark, $lightness: 20%) !default;
$dark-500: $dark !default;
$dark-600:  color.adjust($dark, $lightness: -20%) !default;
$dark-700:  color.adjust($dark, $lightness: -40%) !default;
$dark-800:  color.adjust($dark, $lightness: -60%) !default;
$dark-900:  color.adjust($dark, $lightness: -80%) !default;

// gray-color varients
$grey-100: #f8f9fa !default;
$grey-200: #e9ecef !default;
$grey-300: #dee2e6 !default;
$grey-400: #ced4da !default;
$grey-500: #adb5bd !default;
$grey-600: #6c757d !default;
$grey-700: #495057 !default;
$grey-800: #343a40 !default;
$grey-900: #212529 !default;

$background-primary: $white;
$background-secondary: $light;
$background-tertiary: $grey-100;

$content-primary: $dark;
$content-secondary: $dark-400;
$content-tertiary: $grey-300;

$border-opaque: $grey-100;
// $border-transparent: $transparent; **
$border-tertiary: $grey-200;

// background
$background-state-disabled: $grey-100;
$background-overlay-dark: rgba(0, 0, 0, 0.3);
$background-overlay-light: rgba(0, 0, 0, 0.08);

$background-success: $success;
$background-danger: $danger;
$background-warning: $warning;
$background-info: $info;
$background-accent: $accent;

$background-light-success: $success-100;
$background-light-danger: $danger-100;
$background-light-warning: $warning-100;
$background-light-info: $info-100;
$background-light-accent: $accent-100;

//content
$content-disabled: $grey-400;
$content-on-color: $white;
$content-accent: $accent;
$content-danger: $danger;
$content-warning: $warning-600;
$content-success: $success;

//border
$border-primary: $primary;
$border-danger: $danger;
$border-secondary: $secondary;
$border-warning: $warning;
$border-success: $success;
$border-info: $info;

$font-regular: "Nunito_Sans_Regular", sans-serif;
$font-semibold: "Nunito_Sans_Semibold", sans-serif;
$font-bold: "Nunito_Sans_Bold", sans-serif;