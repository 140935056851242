@use "../../color_variables.scss" as colorVars;

.form-control {
  height: 40px !important;
  padding: 6px 12px !important;
  font-size: 14px !important;
  line-height: 1.42857 !important;
  color: colorVars.$grey-800 !important;
  background-color: colorVars.$white !important;
  background-image: none;
  border: 1px solid colorVars.$grey-200;
  border-radius: 4px !important;
  box-shadow: none;
}

.form-control:focus {
  border-color: colorVars.$primary !important;
  color: colorVars.$grey-800 !important;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px colorVars.$dark-100, 0 0 8px colorVars.$primary-400 !important;
}

.form-control::-moz-placeholder {
  color: colorVars.$grey-500;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: colorVars.$grey-500;
}

.form-control::-webkit-input-placeholder {
  color: colorVars.$grey-500;
}

//checkboxes
.form-check-input:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.form-check-input {
  margin-top: 2px !important;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image:url('../images/checked-mark.png') !important;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: colorVars.$grey-400;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: colorVars.$grey-400;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: colorVars.$grey-400;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
