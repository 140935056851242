@use "./color_variables.scss" as colorVars;
@use "./assets/stylesheet/forms.scss" as forms;

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Nunito_Sans_Regular";
  src: url("./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito_Sans_Bold";
  src: url("./assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito_Sans_Semibold";
  src: url("./assets/fonts/Nunito_Sans/NunitoSans-SemiBold.ttf")
    format("truetype");
  font-display: swap;
  font-weight: normal;
}

body {
  font-family: colorVars.$font-regular !important;
  font-size: 14px !important;
  line-height: 1.42857 !important;
  color: colorVars.$grey-800 !important;
  background: colorVars.$light !important;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px colorVars.$grey-300;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: colorVars.$grey-500;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: colorVars.$grey-500;
}

//buttons
.btn-primary {
  color: colorVars.$white !important;
  background-color: colorVars.$primary-500 !important;
  border-color: colorVars.$primary-500 !important;
  border-radius: 500px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.grey-chip {
  background-color: colorVars.$grey-400 !important;
  border-radius: 50px !important;
  padding: 4px 20px !important;
  &.btn {
    font-size: 12px !important;
    font-weight: 600;
  }
}
.btn-secondary {
  color: colorVars.$primary-500 !important;
  background-color: colorVars.$white !important;
  border-color: colorVars.$primary-500 !important;
  border-radius: 500px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.btn-basic {
  color: colorVars.$primary-500 !important;
  border-color: colorVars.$primary-500 !important;
  background-color: colorVars.$white !important;
  border-radius: 500px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.m-16 {
  margin: 16px;
}
.m-0 {
  margin: 0;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-5 {
  margin-left: 5px;
}

.ml-6 {
  margin-left: 6px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-44 {
  margin-left: 44px;
}

.ml-20-p {
  margin-left: 20%;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-32 {
  margin-top: 32px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-60 {
  margin-top: 60px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px;
}
.mr-25 {
  margin-right: 25px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-44 {
  margin-right: 44px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mtp-4 {
  margin-top: 4%;
}

.mtp-9 {
  margin-top: 9%;
}

.pl-1 {
  padding-left: 1px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-16 {
  padding-left: 16px;
}
.pl-30 {
  padding-left: 30px;
}

.pl-inherit {
  padding-left: inherit;
}

.pt-10 {
  padding-top: 10px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-6 {
  padding-right: 6px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-12 {
  padding-right: 12px;
}

.pr-16 {
  padding-right: 16px;
}

.w-100-p {
  width: 100%;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-800 {
  max-width: 800px;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid colorVars.$grey-300;
}
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

.cursor-not-allowed {
  cursor: text !important;
}
.cursor-disabled {
  cursor: not-allowed;
}
.overview-task-subtext {
  font-family: colorVars.$font-semibold;
}
.danger-text {
  color: colorVars.$danger;
}
.slick-track {
  margin: 0 !important;
  display: flex !important;
}

.slick-slide img {
  display: inline !important;
}
.slick-list {
  width: 99% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.prev-slide.slick-disabled,
.next-slide.slick-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.prev-slide,
.next-slide {
  position: absolute;
  top: 47%;
  bottom: 0;
  width: 50px;
  height: 50px;
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
  border-radius: 999px;
  background: colorVars.$dark;
  color: colorVars.$white;
  border: none;
  padding: 15px 20px;
}

.prev-slide {
  left: -47px;
  z-index: 1;
}

.next-slide {
  right: -50px;
}

.slick-dots li button {
  border: none !important;
  outline: none !important;
}

.slick-dots li button:before {
  top: 10px !important;
  font-size: 12px !important;
}

.slick-dots li button:hover {
  border: none !important;
  outline: none !important;
}

.slick-dots {
  > li {
    display: inline-block;
  }
  > li:only-child {
    display: none;
  }
}

//admin dashboard
.main {
  min-height: calc(100vh - 71px);
  background: #f8f9fa;
  padding: 30px 20px;
  border-left: 1px solid colorVars.$grey-300;
  position: absolute;
  width: 100%;
  border-top: solid 1px colorVars.$grey-200;
}
.display-flex {
  display: flex !important;
}
.display-inline-flex {
  display: inline-flex !important;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.on-track-status {
  font-family: colorVars.$font-semibold;
  background-color: colorVars.$success-200;
  border-radius: 100px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 6px 10px 6px 30px;
  color: colorVars.$secondary-600;
  background-image: url("./assets/images/dashboard-imgs/success-status.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  width: fit-content;
}
.off-track-status {
  font-family: colorVars.$font-semibold;
  background-color: colorVars.$danger-300;
  border-radius: 100px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 6px 10px 6px 30px;
  color: colorVars.$danger-600;
  background-image: url("./assets/images/dashboard-imgs/danger-status.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  width: fit-content;
}
.at-risk-status {
  font-family: colorVars.$font-semibold;
  background-color: colorVars.$warning-300;
  border-radius: 100px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 6px 10px 6px 30px;
  color: colorVars.$warning-600;
  background-image: url("./assets/images/dashboard-imgs/warning-status.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
}
.at-inactive-status {
  font-family: colorVars.$font-semibold;
  background-color: colorVars.$grey-200;
  border-radius: 100px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 6px 10px 6px 30px;
  color: colorVars.$grey;
  background-image: url("./assets/images/dashboard-imgs/inactive-status.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  width: fit-content;
  &.at-active-req {
    padding: 6px 10px 6px 35px;
  }
}
@media screen and (max-width: 1024px) {
  .slick-list {
    width: 90% !important;
  }

  .prev-slide {
    left: 0;
  }

  .next-slide {
    right: 0;
  }
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-muted {
  color: colorVars.$grey;
}

.nav:after,
.nav:before {
  content: " ";
  display: table;
}

.nav:after {
  clear: both;
}

.nav > li,
.nav > li > a {
  position: relative;
  display: block;
}

.nav > li > a {
  padding: 10px 15px;
}

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: colorVars.$white;
}

.nav > li.disabled > a {
  color: colorVars.$grey-700;
}

.nav > li.disabled > a:focus,
.nav > li.disabled > a:hover {
  color: colorVars.$grey-700;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: none;
  border-color: colorVars.$primary-500;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: colorVars.$grey-200;
}

.nav > li > a > img {
  max-width: none;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none;
  }
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
@media (max-width: 991px) {
  nav.navbar.navbar-default#dashboard .nav.navbar-nav {
    margin: 0;
    display: inline-table;
  }

  nav.navbar.navbar-default#dashboard .nav.navbar-nav > li {
    float: left;
  }

  nav.navbar.navbar-default#dashboard .nav.navbar-nav > li a.toggleClass {
    padding-right: 15px;
    padding-left: 0px;
  }

  nav.isRiStateCss .nav.navbar-nav {
    margin: 0;
    display: inline-table;
  }

  nav.isRiStateCss .nav.navbar-nav > li {
    float: left;
  }

  nav.isRiStateCss .nav.navbar-nav > li a.toggleClass {
    padding-right: 15px;
    padding-left: 0px;
  }

  nav.isRiStateCss .navbar-brand > img.widescreen--logo {
    display: none !important;
  }

  nav.isRiStateCss .navbar-brand > img.mobile--logo {
    display: block !important;
  }

  nav.navbar.navbar-default#dashboard .navbar-header {
    float: left;
  }

  nav.navbar.navbar-default#dashboard .navbar-right {
    float: right;
  }

  nav.navbar.navbar-default#dashboard .navbar-right > li {
    position: absolute;
    right: 0;
  }

  nav.navbar.navbar-default#dashboard .navbar-right > li.dropdown > a {
    text-align: right;
    padding-right: 15px;
  }

  nav.navbar.navbar-default#dashboard .navbar-right > li.dropdown > a > span {
    display: none;
  }

  nav.isRiStateCss .navbar-header {
    float: left;
  }

  nav.isRiStateCss .navbar-right {
    float: right;
  }

  nav.isRiStateCss .navbar-right > li {
    position: absolute;
    right: 0;
  }

  nav.isRiStateCss .navbar-right > li.dropdown > a {
    text-align: right;
    padding-right: 15px;
  }

  nav.isRiStateCss .navbar-right > li.dropdown > a > span {
    display: none;
  }

  .content > app-side-bar > .nav#sidebar {
    width: 200px;
    display: none !important;
    position: absolute !important;
    top: 0;
    left: 0;
    background: colorVars.$white;
    z-index: 99;
    height: 100vh;
  }

  .content > app-side-bar > .nav#sidebar .sidebar {
    padding-top: 0;
  }

  .content > .main {
    left: 0;
    width: 100%;
    position: absolute;
  }

  .content.small > app-side-bar > .nav#sidebar {
    width: 200px !important;
    display: block !important;
  }

  .content.small
    > app-side-bar
    > .nav#sidebar
    .sidebar.list-group
    .list-group-item
    span {
    display: inline-block !important;
  }

  .content.small > app-side-bar > .main {
    left: 0;
    width: 100%;
  }
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-24 {
  font-size: 24px;
  font-family: colorVars.$font-semibold;
}
.text-bold {
  font-weight: 600;
}

.fa-chevron-right {
  font-weight: bold;
  font-size: 12px;
}

.display-none {
  display: none !important;
}
.theme-green .bs-datepicker-head {
  background-color: colorVars.$primary-500 !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: colorVars.$primary-500 !important;
}

.theme-green .bs-datepicker-body table td.week span {
  color: colorVars.$primary-500 !important;
}
bs-datepicker-container,
.bs-datepicker-container {
  padding: 0px !important;
  z-index: 9999 !important;
}
.bs-datepicker-body table th {
  padding-left: 16px !important;
  font-size: 10px !important;
}

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: colorVars.$white;
  box-shadow: 0 0 10px 0 colorVars.$grey-500;
  position: relative;
  left: 25px !important;
  z-index: 9999;
}

.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}

.bs-datepicker bs-day-picker {
  float: left;
}

.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active,
.bs-datepicker-btns button:hover,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:active,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus {
  outline: none;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}

.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: colorVars.$white;
  transition: 0.3s;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover,
.bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: colorVars.$grey-100;
  cursor: not-allowed;
}

.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid colorVars.$grey-200;
  /* .bs-datepicker-body table */
}

.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}

.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}

.bs-datepicker-body table th {
  font-size: 13px;
  color: colorVars.$grey-400;
  font-weight: 400;
  text-align: center;
}

.bs-datepicker-body table td {
  color: colorVars.$grey-600;
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  position: relative;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker-body table td:not(.disabled) span {
  cursor: pointer;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: colorVars.$grey-200;
  transition: 0s;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: colorVars.$grey-400;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  color: colorVars.$white;
}

.bs-datepicker-body table td.active {
  position: relative;
}

.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}

.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}

.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td.active.select-start span:after,
.bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}

.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}

.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}

.bs-datepicker-body table td span[class*="select-"],
.bs-datepicker-body table td[class*="select-"] span {
  border-radius: 50%;
  color: colorVars.$white;
}

.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: colorVars.$grey-200;
}

.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before,
.bs-datepicker-body table.days span.in-range.select-end:before {
  background: none;
  right: 0;
  left: 0;
}

.bs-datepicker-body table.days td.select-start + td.select-end:before,
.bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.select-end:before,
.bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: colorVars.$grey-200;
  width: 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}

.bs-datepicker-body table.months td {
  height: 52px;
}

.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker .current-timedate {
  color: colorVars.$grey-600;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid colorVars.$grey-200;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
  display: inline-block;
  border-radius: 4px 0 0 4px;
}

.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}

.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}

.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}

.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: colorVars.$grey-300;
}

.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: colorVars.$grey-400;
  border-radius: 4px;
  color: colorVars.$white;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}

.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:hover {
  background-color: colorVars.$grey-600;
}

.bs-datepicker .is-other-month {
  color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid colorVars.$grey-200;
}

.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}
/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker-multiple {
    display: flex;
  }

  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}

.review-card {
  margin: 20px 0 !important;
  background: colorVars.$white;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  flex: none;
  padding: 16px;
  order: 0;
  flex-grow: 1;
  border-radius: 16px;
  border: solid 1px colorVars.$grey-200;
}
.header-text {
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: colorVars.$dark;
}

.header-subtext {
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  font-weight: 400;
  color: colorVars.$grey;
}

.update-action-btn {
  justify-content: end;
  margin-top: 15px;
  margin-left: 15px;
  padding: 5px 10px;
  text-align: center;
  border: 2px solid colorVars.$info;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  background: transparent;
  color: colorVars.$info;
  cursor: pointer;
}

//banner css

.banner-container {
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: colorVars.$white;
  padding: 10px 10%;
}

.banner-container .banner-title a {
  color: colorVars.$white !important;
  font-weight: 600;
  margin-left: 25px;
}

.banner-container .banner-title span {
  margin-left: 15px;
}

.banner-container .banner-title img {
  margin-top: -4px;
}

.banner-title {
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: colorVars.$white;
  letter-spacing: 0.3px;
}

button:focus,
button:hover,
button:active {
  outline: none !important;
  border: 1px solid colorVars.$primary-500 !important;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
label {
  font-weight: 400;
}
@media (min-width: 768px) {
  .ob-mobile-view {
    display: none;
  }
}

@media (max-width: 768px) {
  .ob-non-mobile-view {
    display: none;
  }
  .widescreen--logo {
    display: none;
  }
  .ob-mobile-view {
    overflow: hidden;
  }
  .ob-carousel-indicators {
    margin-block-end: 0em;
  }
}
.ob-carousel-indicators {
  position: absolute;
  left: 22%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  list-style: none;
  top: 42px;
  &.active {
    background: colorVars.$black;
  }
  li {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 4px;
    text-indent: -999px;
    cursor: pointer;
    border: 1px solid colorVars.$black;
    border-radius: 10px;
    &.active {
      background: colorVars.$black;
    }
  }
}

.custom-back-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.panel-heading {
  margin: 0;
  font-family: colorVars.$font-semibold;
  font-weight: 600 !important;
  font-size: 24px;
  padding: 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.ob-main-content {
  margin: 10px !important;
  padding: 0px 20%;
  font-size: 15px;
}
.panel-body {
  padding: 15px;
}
.oo-main {
  padding: 20px 20px !important;
  min-height: calc(109vh - 60px) !important;
}
.small,
small {
  font-size: 85%;
}

.text-start {
  text-align: start;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.mt10 {
  margin-top: 10px !important;
}

.mt25 {
  margin-top: 25px !important;
}
.p-25 {
  padding: 25px !important;
}
.bg-white {
  background-color: colorVars.$white !important;
}

.font-wt-60 {
  font-weight: 600;
}
.mb10 {
  margin-bottom: 10px;
}
.warning-box {
  background-color: colorVars.$warning-300;
  color: colorVars.$warning-600;
  padding: 15px;
  display: flex;
  border: 1px solid colorVars.$warning-600;
  border-radius: 4px;

  .warning-icon {
    line-height: 55px;
    margin-right: 15px;
  }
  p {
    margin: 0 !important;
    a {
      color: colorVars.$warning-600;
      font-weight: 700;
      text-decoration: underline !important;
    }
  }
}
.ml5 {
  margin-left: 5px !important;
}
.course-block {
  display: flex;
  flex-wrap: wrap;
}
.color-primary {
  color: colorVars.$primary;
}

//ng-select library css

#stateselect .ng-select .ng-select-container {
  min-height: 36.5px !important;
}

#stateselect .ng-select .ng-select-container .ng-value-container {
  border-top: none !important;
}

#fileSelect .ng-select .ng-select-container {
  width: 112% !important;
}

#fileSelect .ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 0em) !important;
}

#stateselect .ng-select .ng-select-container {
  min-height: 36.5px !important;
}

#stateselect .ng-select .ng-select-container .ng-value-container {
  border-top: none !important;
}

#fileSelect .ng-select .ng-select-container {
  width: 112% !important;
}

#fileSelect .ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 0em) !important;
}

@media (max-width: 768px) {
  .ng-dropdown-panel.ng-select-top {
    box-shadow: none !important;
  }

  .ng-dropdown-panel.ng-select-bottom {
    box-shadow: none !important;
  }
}

@media (max-width: 648px) {
  table tbody {
    min-width: 100%;
    /* display: table; */
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 0 5px !important;
  }

  .select-sort-by .ng-value-container {
    padding: 1em 0 0 0 !important;
  }

  .ob-main-content {
    margin: 0px !important;
    padding: 0px 10% !important;
  }

  .ob-mob-main-content .mt25 {
    margin-top: 15px !important;
  }

  .ob-mob-main-content .spec-mfg0 {
    margin-bottom: 0px !important;
  }

  .ob-mob-main-content .ng-value-container {
    padding: 1em 0 0 0 !important;
  }

  .ob-mob-main-content .ng-select {
    padding-bottom: 0.25em !important;
  }

  .ob-mob-main-content .list-group:first-child,
  .ob-mob-main-content .table,
  .ob-mob-main-content .alert,
  .ob-mob-main-content .btn-primary {
    margin-bottom: 10px !important;
  }

  .ob-mob-main-content .save-specs-btn {
    margin: 0px !important;
  }

  .bs-datepicker-body table th {
    padding-left: 16px !important;
    font-size: 10px !important;
  }

  .db-task-dd .ng-value-container {
    padding: 2em 0 0 0.2em !important;
  }

  h4 {
    font-size: 14px !important;
  }
}
.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  bottom: 1px !important;
}

.ob-mob-main-content .ng-select {
  padding-bottom: 0px !important;
}

.ng-select .ng-select-container {
  min-height: 25px !important;
}

.ob-mob-main-content .ng-value-container {
  padding: 0 !important;
}

.ng-select {
  padding-bottom: 1.25em;
}
.ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 0.25em) !important;
}

#add_state_licenses .ng-select {
  padding-bottom: 0.25em !important;
}

#add_state_licenses .ng-select .ng-select-container {
  min-height: 35px !important;
}

#add_state_licenses .state-licence-dropdown .ng-value-container {
  padding: 1em 0 0 0.2em !important;
}

#specialization_fields .ng-select .ng-select-container {
  min-height: 45px !important;
}

#specialization_fields .dropdown-padding {
  padding: 0 !important;
}

#specialization_fields .ng-value-container {
  padding: 12px 0 0 0 !important;
}

#wla_task_completion .ng-select .ng-select-container {
  min-height: 10px;
}

#wla_task_completion .ng-value {
  margin: 0 47% !important;
  font-size: 18px !important;
}

.ng-select {
  padding-bottom: 1.25em;
}

.ng-select.ng-select-disabled .ng-select-container:after {
  border-bottom-color: transparent;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.42) 0%,
    rgba(0, 0, 0, 0.42) 33%,
    transparent 0%
  );
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.ng-select.ng-select-disabled
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: rgba(0, 0, 0, 0.38);
}

.ng-select.ng-select-disabled
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-disabled .ng-clear-wrapper {
  color: rgba(0, 0, 0, 0.38);
}

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: colorVars.$primary-500;
  border-width: 2px;
}

.ng-select.ng-select-focused
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.001px);
  color: colorVars.$primary-500;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: colorVars.$primary-500;
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-filtered .ng-select-container .ng-placeholder {
  display: initial;
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.001px);
}

.ng-select .ng-select-container {
  align-items: baseline;
  min-height: 51.5px;
}

.ng-select .ng-select-container:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  content: "";
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ng-select .ng-select-container .ng-value-container {
  align-items: baseline;
  // padding: 0.4375em 0;
  // border-top: 0.84375em solid transparent;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  transform-origin: 0 0;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ng-select .ng-select-container .ng-value-container .ng-input {
  bottom: 0.4375em;
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  align-self: flex-end;
  bottom: 9px;
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  align-self: flex-end;
  bottom: 7px;
}

.ng-select.ng-select-multiple.ng-select-disabled
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin-right: 0.4375em;
  margin-bottom: 0.4375em;
  background-color: colorVars.$primary-500;
  border-radius: 2px;
  color: colorVars.$white;
  padding: 2px 5px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  font-size: 14px;
  font-weight: 500;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: rgba(255, 255, 255, 0.54);
  padding-right: 5px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  color: rgba(255, 255, 255, 0.87);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  line-height: 1.375em;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value {
  align-items: center;
}

.ng-select.ng-select-multiple
  .ng-select-container.ng-has-value
  .ng-value-container {
  padding-bottom: 0;
  padding-top: 1.1875em;
}

.ng-select.ng-select-multiple
  .ng-select-container.ng-has-value
  .ng-clear-wrapper,
.ng-select.ng-select-multiple
  .ng-select-container.ng-has-value
  .ng-arrow-wrapper {
  border-top: 0.84375em solid transparent;
}

.ng-select .ng-clear-wrapper {
  color: rgba(0, 0, 0, 0.54);
}

.ng-select .ng-clear-wrapper:hover {
  color: rgba(0, 0, 0, 0.87);
}

.ng-select .ng-arrow-wrapper {
  bottom: 2px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.54);
}

.ng-select .ng-spinner-zone {
  top: 3px;
}

.ng-dropdown-panel {
  background: colorVars.$white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  background: #e2e2e2 !important;
  letter-spacing: 1px !important;
}

.ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 1.25em);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.ng-dropdown-panel.ng-select-top {
  bottom: calc(100% - 0.84375em);
  box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2),
    0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12);
}

.ng-dropdown-panel.multiple .ng-option.selected {
  background: colorVars.$white;
}

.ng-dropdown-panel.multiple .ng-option.marked {
  background: rgba(0, 0, 0, 0.04);
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 16px;
  line-height: 3em;
  min-height: 3em;
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 16px;
  line-height: 3em;
  min-height: 3em;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  cursor: pointer;
  line-height: 3em;
  height: 3em;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: colorVars.$primary-500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 3em;
  min-height: 3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: colorVars.$primary-500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-right: 5px;
  font-size: 80%;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.38);
}

#add_board_licenses .ng-dropdown-panel {
  width: 93% !important;
}
.mb-1 {
  margin-bottom: 1px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.dropzone {
  border-radius: 4.2px;
  border: 1.1px dashed colorVars.$primary;
  max-width: 395px;
  height: 262px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  padding: 20px;
}
.dropzone .dropzone__content img {
  cursor: pointer;
}

.vh-95 {
  height: 95vh !important;
}

.p-20 {
  padding: 20px !important;
}

//authentication pages
.auth-card-header {
  background-color: colorVars.$white !important;
  padding: 15px !important;
  font-size: 16px;
  font-weight: 600;
}
.card-footer-main {
  background-color: colorVars.$white !important;
  padding: 15px !important;
}
.auth-card-footer-link {
  text-decoration: none;
  color: colorVars.$primary;
}
.auth-card-footer-link:hover {
  text-decoration: underline !important;
  color: colorVars.$primary;
}
.take-me-to-dashboard {
  cursor: pointer;
  color: colorVars.$primary;
  font-weight: 600;
  font-size: 16px;
}
.take-me-to-dashboard:hover {
  text-decoration: underline !important;
}
.btn {
  font-size: 14px !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: colorVars.$black !important;
  background-color: colorVars.$white !important;
}

.accordion-button:focus,
.accordion-button:hover,
.accordion-button:active {
  outline: none !important;
  border: none !important;
}
.btn-close:focus,
.btn-close:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.expired-status {
  background-color: colorVars.$danger;
  border-radius: 100px;
  background-image: url("assets/images/calendar.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  padding: 6px 15px 6px 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: colorVars.$white;
}

.lifetime-membership-status {
  font-family: colorVars.$font-semibold;
  background-color: colorVars.$info-300;
  border-radius: 100px;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 6px 10px 6px 30px;
  color: colorVars.$info-600;
  background-image: url("assets/images/dashboard-imgs/lifetime-membership.svg");
  background-repeat: no-repeat;
  background-position: 5% center;
}
.completed-status {
  background-color: colorVars.$info-300;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  padding: 6px 10px 6px 30px;
  color: colorVars.$info;
  background-image: url("./assets/images/dashboard-imgs/completed-status.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
}

.max-w-800 {
  max-width: 800px !important;
}
.modal-content {
  border-radius: 16px !important;
}

.review-popup-body {
  padding: 55px !important;
}
.review-header {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: colorVars.$dark;
}
.review-sub-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: colorVars.$dark;
}
.btn-review {
  width: 200px !important;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.modal-header-bottom-none {
  border-bottom: none;
}
p {
  margin: 0 0 10px !important;
}
.color-grey {
  color: colorVars.$grey-500 !important;
}
.color-black {
  color: colorVars.$dark !important;
}

//nav css
.nav-tabs .new-custom-tab a {
  font-size: 12px;
  line-height: 16px;
  font-family: colorVars.$font-regular;
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 0;
  border: none;
  letter-spacing: 0.5px;
  color: colorVars.$white;
  background: #4ebfa4;
  padding: 8px 20px 8px 20px;
  border-radius: 10px 10px 0 0;
}
.custom-active-tab,
.new-custom-tab a:active,
.new-custom-tab a:focus,
.new-custom-tab a:hover {
  color: colorVars.$white !important;
  font-weight: 600 !important;
  background-color: colorVars.$primary-500 !important;
  border: none !important;
  cursor: default !important;
  cursor: pointer !important;
}
.img-circle {
  border-radius: 50%;
}
.border-radius-20 {
  border-radius: 20px !important;
}
.plan-container {
  display: inline-flex;
  background: colorVars.$grey-300;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.plan {
  padding: 10px;
  color: colorVars.$primary;
}
.active-plan {
  background: colorVars.$primary !important;
  color: colorVars.$white !important;
  border-radius: 4px !important;
}

.offer {
  background: colorVars.$secondary !important;
  padding: 2px 5px !important;
  color: colorVars.$white !important;
  font-size: 10px !important;
  margin-left: 5px !important;
  border-radius: 4px !important;
}
.new-plan-price {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: colorVars.$primary;
}
.trial-expire {
  padding: 15px 25px;
  width: 100%;
  border: 2px solid;
  border-radius: 8px;
  border-color: colorVars.$primary;
  color: colorVars.$primary;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
.plan-details {
  width: 60% !important;
  text-align: left !important;
}
.profile-plan-amt {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 3px;
}

.profile-plan-cycle {
  color: colorVars.$grey-300;
  margin-top: 2px;
}
.cancel-subscription {
  width: 40% !important;
  text-align: right !important;
  vertical-align: middle;
  line-height: 70px;
  color: colorVars.$primary-500;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.7px;
}
.payment-status-container {
  padding: 25px;
}
.payment-status {
  font-size: 30px;
  font-weight: 600;
}
.payment-status-container img {
  height: 50px !important;
  margin: 10px 0 30px !important;
}
.payment-message {
  font-size: 20px;
}

a:focus,
a:hover {
  color: colorVars.$primary-500;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a {
  color: colorVars.$primary-500;
  text-decoration: none !important;
  cursor: pointer;
}
.max-width-600 {
  max-width: 600px !important ;
}
.border-right-1 {
  border-right: 1px solid colorVars.$grey-300 !important;
}
.border-left-1 {
  border-left: 1px solid colorVars.$grey-300 !important;
}

//pagination css
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: colorVars.$primary-500;
  background-color: colorVars.$white;
  border: 1px solid colorVars.$grey-300;
  margin-left: -1px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: colorVars.$primary-500;
  background-color: colorVars.$grey-300;
  border-color: colorVars.$grey-300;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: colorVars.$white;
  background-color: colorVars.$primary-500;
  border-color: colorVars.$primary-500;
  cursor: default;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: colorVars.$grey;
  background-color: colorVars.$white;
  border-color: colorVars.$grey-300;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.pagination {
  font-family: colorVars.$font-regular;
  font-size: 13px;
}

.pagination span {
  margin-right: 10px;
}

.pagination i {
  border: thin solid colorVars.$grey-200;
  color: colorVars.$grey-200;
  border-radius: 500px;
  padding: 5px;
  margin: 0 2px;
}

.pagination a > i {
  border: thin solid colorVars.$info-300;
  color: colorVars.$info-300;
}

.pagination a:hover > i {
  border: thin solid colorVars.$info-300;
  color: colorVars.$info-300;
}
.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: colorVars.$primary-500 !important;
  color: colorVars.$grey-100;
  cursor: default;
}
.ngx-pagination {
  margin-top: 1rem !important;
}
.ngx-pagination {
  margin: 5px 0px !important;
}
.ngx-pagination {
  padding-left: 0px !important;
}
.ngx-pagination li,
.pagination {
  font-size: 14px;
}
th.cell--narrow {
  width: 50px;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.ml {
  margin-left: 6px;
}
.nav-button {
  position: absolute;
  top: -6px;
  right: 0;
}
@media screen and (max-width: 440px) {
  .nav-button {
    position: relative !important;
    top: 7px !important;
    text-align: end;
  }
}

.speciality-level {
  background: linear-gradient(0deg, colorVars.$light, colorVars.$light),
    colorVars.$primary;
  padding: 10px 15px;
  justify-content: space-between;
}
.volunteer_title {
  font-size: 27px;
}
.volunteer-navbar {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #f8f9fa;
}

.file-cabinet-header {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .search-bar-container {
    width: calc(100% - 80px);
    .search-bar {
      border: 1px solid #d5dbe4;
      width: 100%;
      height: 40px;
      margin: 0 0 18px 0;
      border-radius: 24px;
      padding: 15px 35px;
    }
    .search-icon {
      position: relative;
      width: 20px;
      height: 20px;
      left: 12px;
      top: 29px;
    }
    .clear-search-icon {
      position: relative;
      bottom: -30px;
      right: 15px;
      width: 20px;
      height: 20px;
    }
  }

  .header-icon-container {
    justify-content: space-between;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
    .disabled {
      opacity: 0.3;
    }
  }
  //will remove this img css block once we implement search box on all component
  img {
    width: 32px;
    height: 32px;
  }
}

//new file cabinet
.file-cabinet-table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
  --bs-table-bg: inherit !important;
  padding-bottom: 100px !important;

  thead {
    background: #f2f2f2;
    th {
      text-align: center;
      height: auto;
      padding: 16px 18px !important;
      border-top: none !important;
      vertical-align: middle !important;
      color: colorVars.$dark !important;
      font-size: 14px !important;
      line-height: 14px !important;
      text-transform: capitalize !important;
      input[type="checkbox"] {
        width: 19px;
        height: 19px;
        margin: 0;
      }
      &:first-child {
        width: 50px;
      }
      &:last-child {
        min-width: 175px;
      }
    }
    tr th:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
    tr th:last-child {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
    tr th {
      border-bottom: 3px solid colorVars.$light !important;
    }
  }
  tbody {
    td {
      background-color: colorVars.$white;
      padding: 24px !important;
      line-height: initial !important;
      vertical-align: middle !important;
      border-top: none !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
      color: colorVars.$dark !important;
    }
    .first-col {
      align-items: center;
    }

    tr td:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
    tr td:last-child {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

    tr:hover {
      td {
        transform: translateY(-2px);
        box-shadow: 0 4px 0px rgba(0, 0, 0, 0.1);
        margin-top: 5px;
        cursor: pointer;
        background-color: colorVars.$primary-300 !important;
      }
    }
    .active-table-row {
      td {
        background-color: colorVars.$white !important;
      }
    }
  }
}
.list-view-file-icon {
  padding: 10px;
  background-color: colorVars.$light;
  max-width: 44px;
  border-radius: 4px;
  cursor: pointer;
}
.list-view-selected,
.list-view-file-icon:hover {
  background-color: transparent !important;
}
.file-cabinet-row {
  &:hover {
    cursor: pointer;
    .list-view-file-icon {
      background-color: transparent;
    }
    .image-action {
      height: 24px;
      width: 24px;
      background-image: url("../src/assets/images/file-cabinet/list-view-unselected.svg");
      background-repeat: no-repeat;
      background-size: cover !important;
    }
    .action-column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .action-column {
    display: none;
  }
}
.image-action {
  height: 24px;
  width: 24px;
  background-image: url("../src/assets/images/file-cabinet/file-text.svg");
  background-size: cover !important;
}

.table-menu {
  font-size: 14px !important;
  color: inherit !important;
  li {
    line-height: 35px;
    height: 35px;
    padding: 0 16px;
    text-align: left;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    i {
      margin-right: 16px;
    }
  }
}

@media (min-width: 1400px) {
  .modal-dialog {
    width: 45%;
  }
  .modal-lg {
    width: 65%;
  }
}
.form-content,
.file-preview-container,
.form-modal-body {
  height: calc(100vh - 250px);
  overflow-y: auto;
}

.form-modal-body {
  height: calc(100vh - 190px);
  overflow-y: auto;
}

.file-cabinet-action-button {
  .btn {
    padding: 0 4px;
    &.disable {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:hover {
      color: colorVars.$dark;
    }
    &:active {
      box-shadow: none;
    }
  }
  .action-img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}
.color-white {
  color: colorVars.$white !important;
}

.wrap-overflow-text {
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.w-70 {
  width: 70px !important;
}
.postition-absolute {
  position: absolute;
}
.extra-btn-pad {
  padding: 6px 30px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.category-block {
  display: flex;
  align-items: center;
  .checkbox-inline {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 0 !important;
    span {
      margin-top: 1px;
      color: colorVars.$black;
    }
  }
}

.border-none {
  border: none !important;
}
.file-container {
  overflow-y: auto;
  min-height: 350px;
}

.m-rl10 {
  margin: 0 10px;
}
.cursor-disabled {
  cursor: not-allowed !important;
}
.breadcrumb-arrow {
  background-image: url(./assets/images/breadcrumb-arrow.svg);
  opacity: 0.6;
  display: inline-block;
  height: 10px;
  width: 10px;
  // margin: 5px;
}
input[type="checkbox"] {
  cursor: pointer !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  margin: 0 -5px;
}
body.modal-open {
  overflow: hidden !important;
}
body modal-container {
  overflow-y: hidden !important;
}
.form-check-label {
  cursor: pointer !important;
}

input:disabled {
  cursor: not-allowed !important;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
.cursor-not-allowed {
  cursor: text !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.pdf-zoom-out,
.pdf-zoom-in {
  margin: 8px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1250px !important;
  }
}
.page-count {
  position: absolute;
  font-style: normal;
  display: flex;
  background-color: colorVars.$dark-400;
  color: colorVars.$white;
  z-index: 1;
  right: 15px;
  top: 10px;
  font-weight: 400;
  font-size: 9px;
  line-height: 18px;
  padding: 1px 10px;
  border-radius: 16px;
}
.cme-page-cont {
  right: 30px;
  top: 65px;
}

.ng2-tag-input .ng2-tags-container {
  padding: 5px !important;
  tag {
    background-color: colorVars.$primary !important;
  }
}

delete-icon path {
  fill: colorVars.$white !important;
}

.share-stat-container .ng2-tag-input {
  border-bottom: none !important;
  background-color: colorVars.$grey-300 !important;
  padding: 5px !important;
}

.share-stat-container .ng2-tag-input.ng2-tag-input--focused {
  border-bottom: none !important;
}

.share-stat-container .ng2-tag-input__text-input {
  background-color: colorVars.$grey-400 !important;
}

.share-stat-container .form-control:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
tag-input-form,
.ng2-tag-input__text-input {
  width: 100% !important;
}

.active-sort-caret {
  color: colorVars.$primary;
}

.inactive-sort-caret {
  color: #aaaaaa;
  &:hover {
    color: colorVars.$primary;
  }
}

.fa {
  line-height: 8px !important;
}
.license-status-toggle-button {
  .status-label {
    padding: 5px;
    color: colorVars.$grey;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
  }
  .status-button {
    width: 81px;
    height: 20px;
    border-radius: 5px;
    margin: 2px 2px 2px 5px;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .status-text {
      font-size: 10px;
      width: 60%;
      height: 100%;
      color: colorVars.$white;
      padding-top: 2px;
      text-align: center;
      border-radius: 5px;
    }
  }
  .toggle-inactive-status-button {
    border: 1px solid colorVars.$danger;
    .inactive-text {
      background-color: colorVars.$danger;
    }
  }

  .toggle-active-status-button {
    border: 1px solid colorVars.$success;
    .active-text {
      background-color: colorVars.$success;
      margin-left: 33px;
    }
  }
}
.is-sort-available {
  &:hover {
    background-color: colorVars.$primary-300;
    cursor: pointer;
  }
}

.selected-practicing-specialty {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 2px 16px;
  gap: 8px;
  margin-right: 10px;
  border-radius: 25px;
  background: linear-gradient(0deg, colorVars.$light, colorVars.$light),
    colorVars.$primary;
  color: colorVars.$primary;
  .close-icon {
    color: colorVars.$grey-300;
    &:hover {
      font-size: 18px;
      font-weight: bolder;
      color: colorVars.$primary;
    }
  }
}
.mb_custom_pdf {
  height: 100% !important;
}
.pdf-viewer {
  height: calc(100vh - 300px);
}
.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

// popper css
.popper {
  display: inline-block;
  background-color: colorVars.$white !important;
  border-radius: 5px;
  border: 1px solid colorVars.$primary;
  padding: 10px;
  width: 30%;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  z-index: 999;
  &[x-placement^="right"] {
    margin-left: 15px;
  }
  &__arrow {
    left: -7px;
    width: 12px;
    height: 12px;
    position: absolute;
    border-style: solid;
    background: colorVars.$white;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
    border-left: 1px solid colorVars.$primary;
    border-bottom: 1px solid colorVars.$primary;
    transform: rotate(45deg);
  }
}

.max-w-175 {
  max-width: 175px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.conditional-info {
  div {
    display: contents !important;
  }
  a {
    text-decoration: underline !important;
    color: colorVars.$primary;
  }
}

.info-section {
  border: 1px solid colorVars.$grey-200;
  border-radius: 4px;
  padding: 15px;
  background: linear-gradient(0deg, colorVars.$light, colorVars.$light),
    colorVars.$info;
  color: colorVars.$info;
  display: flex;

  .info-icon {
    line-height: 40px;
    margin-right: 15px;
  }
  p {
    margin: 0;
  }
}

.nlc-badge {
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16.37px !important;
  color: colorVars.$primary !important;
  padding: 3px 8px !important;
  border-radius: 4px !important;
  background: colorVars.$primary-300;
}
.nlc-home {
  width: 20px;
  height: 20px;
}

/* Create a custom checkbox */
.custom-checkbox-container {
  position: relative;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20px;
  height: 18px;
  width: 18px;
  background-color: colorVars.$grey-100;
  border: 1px solid colorVars.$grey-500;
  border-radius: 4px;
  transform: translate(-50%, -50%);
}
.medium-checkmark {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20px;
  height: 23px;
  width: 23px;
  background-color: colorVars.$grey-100;
  border: 1px solid colorVars.$grey-500;
  border-radius: 4px;
  transform: translate(-50%, -50%);
}

.select-all-checkbox {
  margin-left: 18px;
  .checkmark {
    left: 0 !important;
  }
  input[type="checkbox"] {
    margin-left: -8px !important;
    margin-top: -4px !important;
  }
}

.custom-checkbox-container input[type="checkbox"] {
  opacity: 0 !important;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  border: 1px solid colorVars.$primary;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: colorVars.$primary;
  border: 1px solid colorVars.$primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid colorVars.$white;
  border-width: 0 2px 2px 0;
  border-radius: 0px 1px 1px 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-checkbox-container .medium-checkmark:after {
  left: 7.5px;
  top: 1px;
  width: 7px;
  height: 14px;
  border: solid colorVars.$white;
  border-width: 0 2px 2px 0;
  border-radius: 0px 1px 1px 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
